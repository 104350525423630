enum Actions {
  // action types
  ADD_BODY_CLASSNAME = "addBodyClassName",
  REMOVE_BODY_CLASSNAME = "removeBodyClassName",
  ADD_BODY_ATTRIBUTE = "addBodyAttribute",
  REMOVE_BODY_ATTRIBUTE = "removeBodyAttribute",
  ADD_CLASSNAME = "addClassName",

  SET_CONCESSIONARIA_LIST = "setConcessionariaListAction",
  SET_MECANICO_LIST = "setMecanicoListAction",
  SET_CONSULTORES_LIST = "setConsultoresListAction",
  SET_CONCESSIONARIA_SELECTED = "setConcessionariaSelectedAction",
  SET_DATA_DASHBOARD_SELECTED = "setDataDashboardAction",
  SET_MODELO_AGENDA = "setModeloAgendaAction",
  SET_BASE_AGENDAMENTOS = "setBaseAgendamentoAction",
  SET_SERVICOS_PARALIZADOS = "setServicosParalizadosAction",
  SET_BASE_ENTREGAS = "setBaseEntregaAction",
  SET_BASE_SERVICOS = "setBaseServicoAction",
  START_AGENDA = "startAgendaAction",
  RESET_AGENDA_INFO = "resetAgendaInfoAction",
  SET_OS_ABERTA = "setOsAbertaAction",
  SET_VEICULO_RECEPCAO = "setVeiculoRecepcaoAction",
  
  UPDATE_USER = "updateUser",
  SET_BREADCRUMB_ACTION = "setBreadcrumbAction",
  
  AUTENTICACAO = "autenticacao",
  LOGADO = "logado",
  LOGOUT = "logout",
  CLEAR = "clear",
  CLEAR_FILTER = "clearFilter",

  START_AGENDA_WEB_SOCKET = "startAgendaWebSocketAction",

  NEW_LISTEN = "newListen",
  REMOVE_LISTEN = "removeListen",
  DISCONNECT_SOCKET = "disconnectSocket",
  ALTER_TITLE = "alterarTitulo"
}

enum Mutations {
  // mutation types
  SET_CLASSNAME_BY_POSITION = "appendBreadcrumb",
  DELETE_AUTH = "logOut",
  SET_AUTH = "setAuth",
  SET_ERROR = "setError",
  DELETE_FILTER = "resetFilter",

  START_SOCKET = "startSocket",
  NOTIFICATION_IN_GERENCIAR = "alterNotificationInGerenciar",
  SAVE_NEW_NOTIFICATION = "salvarNovaNotification",
  DELETE_ALL_NOTIFICATION = "deletarTodasNotifications",

  SET_DATA_DASHBOARD_SELECTED = "setDataDashboardMutation",
  SET_CONCESSIONARIA_SELECTED = "setConcessionariaSelectedMutation",
  SET_BREADCRUMB_MUTATION = "setBreadcrumbMutation",
  SET_CONCESSIONARIA_LIST = "setConcessionariaList",
  SET_LAYOUT_CONFIG = "setLayoutConfig",
  RESET_LAYOUT_CONFIG = "resetLayoutConfig",
  OVERRIDE_LAYOUT_CONFIG = "overrideLayoutConfig",
  OVERRIDE_PAGE_LAYOUT_CONFIG = "overridePageLayoutConfig",

  SET_MECANICO_LIST = "setMecanicoListMutation",
  SET_CONSULTORES_LIST = "setConsultoresListMutation",
  SET_MODELO_AGENDA = "setModeloAgendaMutation",
  SET_BASE_AGENDAMENTOS = "setBaseAgendamentoMutation",
  SET_SERVICOS_PARALIZADOS = "setServicosParalizadosMutation",
  SET_BASE_ENTREGAS = "setBaseEntregaMutation",
  SET_BASE_SERVICOS = "setBaseServicoMutation",
  SET_OS_ABERTA = "setOsAbertaMutation",
  SET_VEICULO_RECEPCAO = "setVeiculoRecepcaoMutation",
}

export { Actions, Mutations };
