import { defineStore } from 'pinia';

export const useSelectedMecanicoStore = defineStore('useSelectedMecanicoStore', {
  state: () => ({
    selecteMecanico: null as string | null,
    isPush: false as boolean
  }),
  actions: {
    setByPush(value: boolean) {
      this.isPush = value;
    },
    setSelectedMecanico(codMecanico: string | null) {
      this.selecteMecanico = codMecanico
      this.setByPush(true)
    },
    clearSelectedMecanico(){
      this.selecteMecanico = null
    }
  },
  getters: {
    getSelectedMecanico: (state) => state.selecteMecanico,
    byPush: (state) => state.isPush
  },
});

export type MecanicoStore = ReturnType<typeof useSelectedMecanicoStore>;
