import { defineStore } from 'pinia';

interface Params {
  mes: string,
  ano: number,
  dia: string
}

export const useSelectedLancamentoMensalStore = defineStore('useSelectedLancamentoMensalStore', {
  state: () => ({
    mes: '',
    ano: 0,
    dia: '',
  }),
  actions: {    
    setLancamentoMensalParams(params: Params) {
      this.mes = params.mes
      this.ano = params.ano
      this.dia = params.dia
    },
    clearSelectedLancamentoMensal() {
      this.mes = '';
      this.ano = 0;
      this.dia = '';
    }
  },
  getters: {
    getParamsLancamentoMensal: (state) => {
      return {
        mes: state.mes,
        ano: state.ano,
        dia: state.dia,
      };
    },
  },
});

export type LancamentoMensalStore = ReturnType<typeof useSelectedLancamentoMensalStore>;
