import { defineStore } from 'pinia';

interface Params {
  codCheckList : number,
  checklist: number,
  codConcessionaria: number
}

export const useAndamentoOficinaDigitalStore = defineStore('useAndamentoOficinaDigitalStore', {
  state: () => ({
    codCheckList : 0,
    checklist: 0,
    codConcessionaria: 0
  }),
  actions: {  
    setSelectedOrcamento(Params: Params) {
      this.codCheckList = Params.codCheckList
      this.checklist = Params.checklist
      this.codConcessionaria = Params.codConcessionaria
    }    
  },
  getters: {
    getOrcamento: (state) => {
      return {
        codCheckList : state.codCheckList,
        checklist : state.checklist,
        codConcessionaria : state.codConcessionaria
      }
    },
  },
});