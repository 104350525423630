import router from '@/router';
import { RouteLocationNormalized, NavigationGuardNext } from 'vue-router';

const cardapioGuard = (
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: NavigationGuardNext
) => {

    const allowedTypes = ['tree', 'pneu', 'itensAdicionais', 'manualdoProprietario'];
    const { tab } = to.params;

    if (!tab) {
        
        next({
            name: 'cardapio',
            params: {
                tab: 'tree'
            }
        });
    } else if (typeof tab === 'string' && allowedTypes.includes(tab)) {
        next();
    } else {
        next({
            name: 'cardapio',
            params: {
                tab: 'tree'
            }
        });
    }
}

export {
    cardapioGuard
}