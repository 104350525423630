import { useSelectedGrupoStore } from '@/pinia/crudGrupo/useGrupoStore';
import { RouteLocationNormalized, NavigationGuardNext } from 'vue-router';

const formGrupoGuard = (
  to: RouteLocationNormalized, 
  from: RouteLocationNormalized, 
  next: NavigationGuardNext
) => {
    const storeGrupo = useSelectedGrupoStore();    
    const byPush = storeGrupo.isPush;
            
    if (!byPush && to.name !== 'cadastros-grupos-list') {
      next({ name: 'cadastros-grupos-list' });
    } else {
      next();
    }
}

export {
  formGrupoGuard
}