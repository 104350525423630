import { useSelectedLancamentoMensalStore } from '@/pinia/vendas/lancamentoMensal/useLancamentoMensalStore';
import { RouteLocationNormalized, NavigationGuardNext } from 'vue-router';

const adcionarLancamentoMensal = (
  to: RouteLocationNormalized, 
  from: RouteLocationNormalized, 
  next: NavigationGuardNext
) => {
    const storeLancamento = useSelectedLancamentoMensalStore();    
    const params = storeLancamento.getParamsLancamentoMensal;
            
    if (!params && to.name !== 'lancamento-mensal') {
      next({ name: 'lancamento-mensal' });
    } else {
      next();
    }
}

export {
  adcionarLancamentoMensal
}