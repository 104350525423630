import { useSelectedUsuarioStore } from '@/pinia/crudUsuario/useUsuarioStore';
import { RouteLocationNormalized, NavigationGuardNext } from 'vue-router';
import { useAndamentoOficinaDigitalStore } from "@/pinia/oficinaDigital/andamento/useAndamentoOficinaDigitalStore"

const precificarGuard = (
  to: RouteLocationNormalized, 
  from: RouteLocationNormalized, 
  next: NavigationGuardNext
) => {
    const storeOfinaDigitalAndamento = useAndamentoOficinaDigitalStore();    
    const params = storeOfinaDigitalAndamento.getOrcamento;
    const isParams = params.checklist && params.codCheckList && params.codConcessionaria
    
    if (!isParams && to.name !== 'gerenciar-oficina-digital') {
      next({ name: 'gerenciar-oficina-digital' });
    } else {
      next();
    }
}

export {
  precificarGuard
}