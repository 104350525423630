import { RouteLocationNormalized, NavigationGuardNext } from 'vue-router';

const copiarGuard = (
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: NavigationGuardNext
) => {
    const allowedTypes = ['peca', 'cardapio', 'manual'];
    const { tipoCopia } = to.params;

    if (typeof tipoCopia === 'string' && allowedTypes.includes(tipoCopia)) {
        next();
    } else {
        next({ name: 'cardapio' });
    }
}

export {
    copiarGuard
}