import { useSelectedConsultorStore } from '@/pinia/crudConsultor/useConsultorStore';
import { RouteLocationNormalized, NavigationGuardNext } from 'vue-router';

const formconsultorGuard = (
  to: RouteLocationNormalized, 
  from: RouteLocationNormalized, 
  next: NavigationGuardNext
) => {
    const storeConsultor = useSelectedConsultorStore();    
    const byPush = storeConsultor.isPush;
            
    if (!byPush && to.name !== 'cadastros-consultores-list') {
      next({ name: 'cadastros-consultores-list' });
    } else {
      next();
    }
}

export {
  formconsultorGuard
}