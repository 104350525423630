import { AutenticacaoModel } from "@/models/AutenticacaoModel";
import { UsuarioModel } from "@/models/UsuarioModel";
import ApiService from "@/services/ApiService";
import AuthService from "@/services/AuthService";
import JwtService from "@/services/JwtService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import store from "@/store/index";

export const setAutenticacao = (credentials: any): Promise<any> => {
  let dominio_gestao = store.getters.layoutConfig("distribuidor.dominio_gestao");
  let tipo_conexao = store.getters.layoutConfig("distribuidor.tipo_conexao");
  let link_v2 = "";

  if(dominio_gestao == "localhost:8080"){
    link_v2 = tipo_conexao+"://localhost/acampa-gestao-pra/v2/autenticacao.php";
  }else{
    link_v2 = "../v2/autenticacao.php";
  }

  return new Promise<any>((resolve, reject) => {
    ApiService.post(Actions.AUTENTICACAO, credentials, false)
    .then(({ data }) => {
      // console.log('retorno login',data);
      // console.log('credentials',credentials);
      if(data) {

        let myHeaders = new Headers();
        myHeaders.append('Access-Control-Allow-Origin', '*');
        myHeaders.append('Access-Control-Allow-Methods', 'GET, POST, PUT, DELETE');
        myHeaders.append('Access-Control-Allow-Headers', '*');
        myHeaders.append('Access-Control-Allow-Credentials', 'true');

        var formdata = new FormData();
        formdata.append("username", credentials.email);
        formdata.append("submit", "");
        formdata.append("password", credentials.senha);
        formdata.append("rememberme", "a");
        
        let requestOptions:any = {
          method: 'POST',
          headers: myHeaders,
          body: formdata,
          redirect: 'follow'
        };
        try {
          
        } catch (error) {
          
        }
        fetch(link_v2, requestOptions)
        .then(response => response.text())
        .then(result => console.log(result))
        .catch(error => console.log('error', error));
        // console.log('sucesso',data);
        resolve(data);
      }else {
        reject({"message":"Acesso não autorizado"});
      }
    })
    .catch(() => {
      reject({"message":"Acesso não autorizado"});
    });
  });
}

@Module
export default class AuthModule extends VuexModule implements AutenticacaoModel {
  errors = [];
  usuario = AuthService.getUsuarioAuth() as UsuarioModel;
  isAuthenticated = !!JwtService.getToken();
  
  /**
   * Get current user object
   * @returns User
   */
  get usuarioLogado(): any {
    // console.log('usuario logado authmodule',this.usuario);
    return this.usuario;
  }

  /**
   * Verify user authentication
   * @returns boolean
   */
  get isUsuarioLogado(): boolean {
    return this.isAuthenticated;
  }

  /**
   * return user codnivel
   * @returns number
   */
   get getCodNivel(): number {

    // console.log('getCodNivel 97');

    let codNivel;
    if(this.isUsuarioLogado)
    {
      let decodeToken = JwtService.decodeToken(this.usuario.token);
      codNivel = decodeToken.codNivel;
    }
    return codNivel;
  }

  /**
   * Get authentification errors
   * @returns array
   */
  get getErrors(): Array<string> {
    return this.errors;
  }

  @Mutation
  [Mutations.SET_ERROR](error) {
    this.errors = error;
  }

  @Mutation
  [Mutations.SET_AUTH](auth) {
    // console.log('SET_AUTH data',auth);
    this.isAuthenticated = true;
    this.usuario = auth.data.usuario;
    this.usuario.token = auth.data.token; 
    this.usuario.email = auth.credentials.email.toString();
    this.usuario.senha = auth.credentials.senha.toString();
    this.usuario.rememberme = auth.credentials.rememberme;
    this.errors = [];
    JwtService.saveToken(auth.data.token, auth.credentials.rememberme);
    AuthService.saveUsuarioAuth(this.usuario, auth.credentials.rememberme);
    // console.log('data.token',auth.data.token);
  }

  @Mutation
  [Mutations.DELETE_AUTH]() {

    let dominio_gestao = store.getters.layoutConfig("distribuidor.dominio_gestao");
    let tipo_conexao = store.getters.layoutConfig("distribuidor.tipo_conexao");

    let link_v2 = "";

    if(dominio_gestao == "localhost:8080"){
      link_v2 = tipo_conexao+"://localhost/acampa-gestao-pra/v2/desautenticar.php";
    }else{
      link_v2 = "../v2/desautenticar.php";
    }

    let myHeaders = new Headers();
    
    myHeaders.append('Access-Control-Allow-Origin', '*');
    myHeaders.append('Access-Control-Allow-Methods', 'GET, POST, PUT, DELETE');
    myHeaders.append('Access-Control-Allow-Headers', '*');
    myHeaders.append('Access-Control-Allow-Credentials', 'true');

    let requestOptions:any = {
      method: 'POST',
      headers: myHeaders,
      body: [],
      redirect: 'follow'
    };
    
    fetch(link_v2, requestOptions)
    .then(response => response.text())
    .catch(error => console.log('error', error));


    this.isAuthenticated = false;
    this.usuario = {} as UsuarioModel;
    this.errors = [];
    JwtService.destroyToken();
    AuthService.destroyUsuarioAuth();
    // apaga outras variaveis usadas
    window.sessionStorage.removeItem("filtroData");
    window.sessionStorage.removeItem("filtroConcessionaria");
    window.localStorage.removeItem("filtroData");
    window.localStorage.removeItem("filtroConcessionaria");
    //
    // console.log('limpou variaveis');
  }

  @Action
  [Actions.AUTENTICACAO](credentials) {
    let autenticacao = setAutenticacao(credentials);
    // console.log('autenticacao',autenticacao);
    autenticacao.then(data => {
      // console.log('resolved', data);
      this.context.commit(Mutations.SET_AUTH, { "data" : data, "credentials" : credentials});

    })
    .catch(response => {
      // console.log('rejected', response);
      this.context.commit(Mutations.SET_ERROR, response.message);
    });
    return autenticacao;
  }

  @Action
  [Actions.LOGOUT]() {
    // console.log('action LOGOUT');
    this.context.commit(Mutations.DELETE_AUTH);  
    // console.log('redireciona para login');
    window.location.href = 'login';
  }

  @Action
  [Actions.CLEAR]() {
    // console.log('clear action');
    this.context.commit(Mutations.DELETE_AUTH);  
  }

  @Action
  [Actions.LOGADO]() {

    return new Promise((resolve, reject) => {

        // console.log('action logado');
      if (JwtService.getToken()) {
        // console.log('action logado tem token');
        ApiService.setHeader();
        ApiService.get(Actions.LOGADO,'',false)
        .then(({ data }) => {
          // esta logado
          // console.log('esta logado 174');
          resolve(true);
        })
        .catch(({ response }) => {

          // console.log('tem o token mas nao é valido 178');
          // console.log('##### usuario ',this.usuario);
          let credentials = {
            "senha":this.usuario.senha,
            "email":this.usuario.email,
            "rememberme":this.usuario.rememberme
          };
          
          let autenticacao = setAutenticacao(credentials);
          // console.log('nova autenticacao',autenticacao);

          autenticacao.then(data => {
            // console.log('nova autenticacao resolved', data);
            this.context.commit(Mutations.SET_AUTH, { "data" : data, "credentials" : credentials});
            ApiService.setHeader();
            resolve(true);
          })
          .catch(response => {
            // console.log('nova autenticacao rejected 193');
            // console.log('nova autenticacao   rejected', response);
            // logout
            this.context.dispatch(Actions.LOGOUT);
            resolve(true);
          });
        });
      } else {
        // console.log('action sem token');
        //this.context.commit(Mutations.DELETE_AUTH);
        resolve(true);
      }

    })
  }
}