import { useSelectedMecanicoStore } from '@/pinia/crudMecanico/useMecanicoStore';
import { RouteLocationNormalized, NavigationGuardNext } from 'vue-router';

const formMecanicoGuard = (
  to: RouteLocationNormalized, 
  from: RouteLocationNormalized, 
  next: NavigationGuardNext
) => {
    const storeMecanico = useSelectedMecanicoStore();    
    const byPush = storeMecanico.isPush;
            
    if (!byPush && to.name !== 'cadastros-mecanicos-list') {
      next({ name: 'cadastros-mecanicos-list' });
    } else {
      next();
    }
}

export {
  formMecanicoGuard
}