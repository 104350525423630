import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { getConcessionarias } from '@/services/GeralService';
import { FilterModel, FilterConcessionariaModel } from "@/models/FilterModel";
import router from "@/router";
import useAlert from '@/composables/Alert';
import AuthService from "@/services/AuthService";

const { showNotificationAlert } = useAlert();


@Module
export default class FilterModule extends VuexModule implements FilterModel {
  concessionariaList:Array<FilterConcessionariaModel> = [];
  concessionariaListAll:Array<FilterConcessionariaModel> = [];
  concessionariaSelected = {} as FilterConcessionariaModel;
  dataDashboard:String = "";
  
  /**
   * Get data dashboard
   * @returns a data selecionada no filtro dashboard
   */
  get dataSelectedDashboard(): any {
    return this.dataDashboard;
  }

  /**
   * Get concessionariaSelectedInfo
   * @returns as informações da concessionárias selecionada
   */
  get concessionariaSelectedInfo(): any {
    return this.concessionariaSelected;
  }

  /**
   * Get concessionariaList
   * @returns a lista de concessionárias do usuário
   */
  get concessionariasList(): any {
    return this.concessionariaList;
  }

    /**
     * Get concessionariaList
     * @returns a lista de concessionárias do usuário ate as desativadas
     */
    get concessionariasListAll(): any {
        return this.concessionariaListAll;
    }

  @Mutation
  [Mutations.DELETE_FILTER]() {
    this.concessionariaList = [];
    this.concessionariaListAll = []
    this.concessionariaSelected = {} as FilterConcessionariaModel;
  }
  
  @Mutation
  [Mutations.SET_CONCESSIONARIA_LIST](concessionarias) {
    this.concessionariaList = concessionarias.filter(concessionarias => concessionarias.status  == 1); //somente habilitadas
    this.concessionariaListAll = concessionarias;
  }

  @Mutation
  [Mutations.SET_CONCESSIONARIA_SELECTED](concessionariaInfo) {
    this.concessionariaSelected = concessionariaInfo;
  }

  @Mutation
  [Mutations.SET_DATA_DASHBOARD_SELECTED](data) {
    this.dataDashboard = data;
  }
  
  @Action
  [Actions.CLEAR_FILTER]() {
    // console.log('clear action');
    this.context.commit(Mutations.DELETE_FILTER);
    window.sessionStorage.removeItem("filtroData");
    window.sessionStorage.removeItem("filtroConcessionaria");
    window.localStorage.removeItem("filtroData");
    window.localStorage.removeItem("filtroConcessionaria");
  }

  @Action
  [Actions.SET_CONCESSIONARIA_SELECTED](codConcessionaria) {

    console.log("notification set_concessionaria_selected", this.concessionariaSelected.codConcessionaria , codConcessionaria);
    if(codConcessionaria!=this.concessionariaSelected.codConcessionaria){
      if(this.concessionariaSelected.codConcessionaria!=undefined){
        this.context.commit(Mutations.DELETE_ALL_NOTIFICATION);
      }
      const userInfo = AuthService.getUsuarioAuth();
      const codNivel = userInfo.codNivel;
      const codRequired =  [1,2,3,-1];
      
      if(codRequired.includes(codNivel)){//se contem oficinaDigital.
            const prefix = "ACAMPA_";
            const distribuidor = this.context.getters.layoutConfig("distribuidor.distribuidor").toLowerCase();
            const socketRoute = "oficinaDigitalOrcamento_";

            let newConcessionaria = prefix+distribuidor+"_"+socketRoute+codConcessionaria;
            let lastConcessionaria = prefix+distribuidor+"_"+socketRoute+this.concessionariaSelected.codConcessionaria;
            this.context.dispatch(Actions.REMOVE_LISTEN,{ name: lastConcessionaria});
            this.context.dispatch(Actions.NEW_LISTEN,{ name: newConcessionaria, callbackFunction: (payload, newConcessionaria) => {
              console.log("notificacao",payload);
            this.context.commit(Mutations.NOTIFICATION_IN_GERENCIAR);
            this.context.commit(Mutations.SAVE_NEW_NOTIFICATION, payload, newConcessionaria);
            this.context.dispatch(Actions.ALTER_TITLE);
              showNotificationAlert(distribuidor,payload.titulo,payload.cliente,payload.placa,() => {       
                router.push("/oficina-digital/gerenciar");
                console.log("router.push");
                })
              }
              });
      }
    }
    let concessionariaInfo = this.concessionariaListAll.find(concessionaria => concessionaria.codConcessionaria == codConcessionaria);
    concessionariaInfo ?? {}
 

    // console.log("concessionariaInfo", concessionariaInfo);
    this.context.commit(Mutations.SET_CONCESSIONARIA_SELECTED, concessionariaInfo);
  }
  
  @Action
  [Actions.SET_DATA_DASHBOARD_SELECTED](data) {    
    this.context.commit(Mutations.SET_DATA_DASHBOARD_SELECTED, data);
  }
  
  @Action
  [Actions.SET_CONCESSIONARIA_LIST]() {
    new Promise<any>((resolve, reject) => {
      getConcessionarias().then(response => {
        
        if(response.length == 0){
          router.push({ name: "erroConcessionaria" });
          return;
        }

        if(response.length == 1){
          window.localStorage.setItem("filtroConcessionaria", response[0].codConcessionaria); 
        }
        
        this.context.commit(Mutations.SET_CONCESSIONARIA_LIST, response);
        const cache = window.localStorage.getItem("filtroConcessionaria");
        const cacheFiltroConcessionaria = cache && cache != "undefined" && cache != null  ? cache : null; 
        if(cacheFiltroConcessionaria){
          this.context.dispatch(Actions.SET_CONCESSIONARIA_SELECTED, cacheFiltroConcessionaria);
        }

        resolve(true);
      })
      .catch(response => {
        reject(response);
        this.context.commit(Mutations.SET_ERROR, response);
      });
    });
  }
}