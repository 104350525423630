import { useSelectedConcessionariaStore } from '@/pinia/crudConcessionaria/useConcessionariaStore';
import { RouteLocationNormalized, NavigationGuardNext } from 'vue-router';

const formConcessionariaGuard = (
  to: RouteLocationNormalized, 
  from: RouteLocationNormalized, 
  next: NavigationGuardNext
) => {
    const storeConcessionaria = useSelectedConcessionariaStore();    
    const byPush = storeConcessionaria.isPush;
            
    if (!byPush && to.name !== 'cadastros-concessionaria-list') {
      next({ name: 'cadastros-concessionaria-list' });
    } else {
      next();
    }
}

export {
  formConcessionariaGuard
}